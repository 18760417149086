import React, { lazy } from 'react';

export const Navbar = lazy(() => import('../../Components/Navbar'));
export const Home = lazy(() => import('../../Pages/Home'));
export const Tower = lazy(() => import('../../Pages/Tower'));
export const Room = lazy(() => import('../../Pages/Room'));
export const Amenities = lazy(() => import('../../Pages/Ameneties'));
export const LazyMap = lazy(() => import('../../Components/Map'));
export const Spa = lazy(() => import("../../Pages/Spa"))
{/*export const Zermatt = lazy(() => import("../../Pages/Zermatt"))*/}
export const SkyLounge = lazy(() => import("../../Pages/SkyLounge"))
export const CoffeeLounge = lazy(() => import("../../Pages/CoffeeLounge"))
export const Management = lazy(() => import("../../Pages/Management"))
export const Contact = lazy(() => import("../../Pages/Contact"))
export const Journal = lazy(() => import('../../Pages/Journal'));
export const Promos = lazy(() => import('../../Pages/Promo'));
export const NotFound = lazy(() => import('../../Components/notFound'));
// eslint-disable-next-line
export const Reservation = lazy(() => import('../../Pages/Reservation'));
export const Blog = lazy(() => import('../../Pages/Blog/Index'));
export const BlogLanding = lazy(() => import('../../Pages/Blog/Landing'));
export const Gallery = lazy(() => import('../../Pages/Gallery'));
export const Feedbacksample = lazy(() => import('../../Pages/Feedback'));